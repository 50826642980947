import React from "react"
import PropTypes from "prop-types"

// Utilities
import kebabCase from "lodash/kebabCase"
import styled from "styled-components"

// Components
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import { BgImage, Container, Layout, Section } from "../components"

const TagsPage = props => {
  const { data } = props
  return (
    <Layout transparentHeader={true}>
      <Helmet title={data.site.siteMetadata.title} />
      <BgImage
        fluid={data.desktop.childImageSharp.fluid}
        height="60vh"
        mobileHeight="50vh"
        title="desktop"
        overlayColor="rgba(0, 0, 0, 0.7)"
        css={`
          display: flex;
          flex-direction: column;
          justify-content: center;
        `}
      >
        <Container>
          <h1
            css={`
              font-family: "Sedgwick Ave Display";
              font-size: 16rem;
              font-weight: normal;
              color: var(--gray-300);
              text-align: center;
            `}
          >
            Tags
          </h1>
        </Container>
      </BgImage>
      <Section paddingTop={"1rem"}>
        <Container>
          <h2>All Tags</h2>
          <TagList>
            {data.allMdx.group.map(tag => (
              <li key={tag.fieldValue}>
                <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                  <svg
                    style={{
                      width: "2rem",
                      verticalAlign: "middle",
                      marginRight: "0.6em",
                    }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="tags"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                    className="svg-inline--fa fa-tags fa-w-20"
                  >
                    <path
                      fill="currentColor"
                      d="M497.941 225.941L286.059 14.059A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0 0 14.059 33.941l211.882 211.882c18.744 18.745 49.136 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zM112 160c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm513.941 133.823L421.823 497.941c-18.745 18.745-49.137 18.745-67.882 0l-.36-.36L527.64 323.522c16.999-16.999 26.36-39.6 26.36-63.64s-9.362-46.641-26.36-63.64L331.397 0h48.721a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882z"
                      className=""
                    ></path>
                  </svg>
                  {tag.fieldValue} ({tag.totalCount})
                </Link>
              </li>
            ))}
          </TagList>
        </Container>
      </Section>
    </Layout>
  )
}

const TagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  > li {
    margin: 1rem 1rem 1rem 0;
    /* &:first-child {
      margin-left: 0;
    } */
    > a {
      background: var(--primary);
      border-radius: 0.3rem;
      color: white;
      font-size: 1.6rem;
      padding: 0.8rem 1rem;
      text-decoration: none;
      transition: all 0.2s linear;
      &:hover {
        background: var(--primary-dark);
      }
    }
  }
`

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    desktop: file(relativePath: { eq: "tags.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
